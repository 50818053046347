import React, { useEffect } from "react";
import './QAservices.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { scrollUp } from "../../Layouts/backtotop/Backtotop";
import Getintouch from "../getintouch/Getintouch";


export default function QAservices(){

    useEffect(()=>{
        scrollUp()
    },[])
    return(
        <>
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>QA Services</title>
                <link rel="canonical" href="/qa-services" />
                <meta name="robots" content="all, index, follow"  />
                <meta property="og-locale" content="en_US"  />
                <meta property="og-type" content="website"  />
                <meta property="og-title" content="QA Services" />
                <meta property="og-description" content="At our company we offer Quality Assurance (QA) services that focus on guaranteeing your software applications meet the standards of performance, reliability and security. Our team of QA experts conducts testing to detect and address any issues, bugs or vulnerabilities, in your software."/>
                
                <meta property="og-url" content="/solution" />
                <meta property="og-site_name" content="Sharptip Technologies" />
                <meta property="og-image" content="/images/logo1.png" />
                <meta property="og:image:secure_url" content="/images/logo1.png" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="At our company we offer Quality Assurance (QA) services that focus on guaranteeing your software applications meet the standards of performance, reliability and security. Our team of QA experts conducts testing to detect and address any issues, bugs or vulnerabilities, in your software." />
                <meta name="twitter:title" content="QA Services - Sharptip Technologies" />
                <meta name="twitter:image" content="/images/logo1.png" />

            </Helmet>

            <div className="QAservices_container" >
                <div className="QAservices_head" >
                <h2>Application Modernization Services</h2>
                <p>In today's fast-paced and highly competitive business landscape, ensuring the quality of your products and services is paramount. This is where our QA services come into play, empowering you to establish unwavering control over your product's life cycle and providing you with invaluable insights into its development stages, ultimately delivering precise product quality information.
               </p>
                </div>  
            </div>  

            <section className="QAservices-section">
                    
                <div className="QA-heading">
                    <h1  >Our Services</h1>
                    <p>For over a decade, our dedicated team of QA experts has relentlessly strived to be at the forefront of the industry, utilizing the latest practices and cutting-edge technologies.
                   
                    This commitment to excellence has enabled us to consistently deliver efficient performance and ensure high-quality project implementation for some of the world's largest enterprises. 
                    We are not just a service provider; we are your trusted partner in delivering solid, reliable software on time.
                    </p>
                </div>
            </section>

            <div className="QA-boxcontainer" >

                <div className="QAboxinner" >
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_one"><i className="fa-solid fa-bug"></i></span>
                        
                            <h6>Comprehensive Quality Assessment</h6>
                            <p>We leave no stone unturned in scrutinizing your products. Our QA services encompass a holistic assessment of your software, ensuring that it meets the highest industry standards and your specific requirements.</p>
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_two"><i className="fa fa-anchor"></i></span>
                            <h6>Proactive Issue Identification</h6>
                            <p>We don't wait for problems to arise; we anticipate and identify potential issues before they can affect your project. This proactive approach ensures seamless development and saves you time and resources.</p>
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_three"><i className="fa fa-hourglass-half"></i></span>
                            <h6>Streamlined Development</h6>
                            <p>Our expertise in QA helps streamline your development process. By integrating QA practices into every stage of your project, we guarantee that your software is built with quality in mind from the start.</p>
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_four"><i className="fa fa-database"></i></span>
                            <h6>Timely Delivery</h6>
                            <p>Our QA practices are designed to expedite development while maintaining exceptional quality, ensuring that your projects are delivered on schedule.</p>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_five"><i className="fa fa-upload"></i></span>
                            <h6>Customized Solutions</h6>
                            <p>We recognize that every project is unique. Our QA experts tailor their approach to fit the specific needs of your project, whether you're a startup or a global enterprise.</p>
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_six"><i className="fa-solid fa-wand-magic-sparkles"></i></span>
                            <h6>Cost-Effective Quality</h6>
                            <p>Our services are not just about enhancing quality; they also offer cost savings. By identifying and mitigating issues early, we help you avoid costly rework and delays.</p>
                        </div>
                    </div>
                    <div >
                        <div className="QAitem"> <span className="icon feature_box_col_six"><i className="fa-solid fa-headset"></i></span>
                            <h6>Continuous Improvement</h6>
                            <p>In the ever-evolving tech landscape, staying up-to-date is crucial. We're committed to continuous learning and adopting the latest industry best practices and technologies to ensure your projects are future-proof.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div style={{padding:' 1rem 8%', textAlign: 'center'}} >
                <h2 className="services_heading" >Enhance Your Software with QA Services</h2>
                <p className="para">Quality assurance is essential to ensure your software application meets the highest standards of performance, reliability, and user satisfaction. If you're looking to deliver a flawless product, we're here to provide top-notch QA services to help you achieve that goal.
                </p>
            </div>
            <Getintouch/>
        </HelmetProvider>
        </>
    )
}