import React from "react";
import "./Headersection.css";
import "react-simple-typewriter";
// import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";

export default function Headersection() {
  return (
    <>
 
    <div className="homesectioncontainer" >
    <video id="background-video" autoPlay loop muted >
            <source src="/images/business.mp4" type="video/mp4" />
            </video>
                                        
    <section className="home" id="home">
      <div className="content">
        <div >
          <h3 data-aos="fade-right" data-aos-duration="1400" >Technology</h3>
         <span data-aos="fade-right" data-aos-duration="1400"  data-aos-delay="1000" className="tech">
          Solution Partner
         </span><br/>
          <span  data-aos="fade-right" data-aos-duration="1400"  data-aos-delay="1300">
            For Your Business
          </span>
        </div>

            {/* <Typewriter
              loop
              cursor
              cursorStyle="|"
              typeSpeed={250}
              deleteSpeed={250}
              delaySpeed={1000}
              words={[" for", "ur business"]}
            ></Typewriter> */}
        <p
          data-aos="fade-right"
          data-aos-delay="1400"
          data-aos-duration="1400"
        >
          
          We help startups and enterprises to go digital by crafting and<br/>
          building software solutions for their business.
        </p>
        <div className="homeimages">
            <div className="designrushimg">
              <Link
                to="https://www.designrush.com/agency/profile/sharptip-technologies-private-limited"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/DESIGN RUSH (1).png" alt="design rush" loading="lazy" ></img>
              </Link>
            </div>
            <div className="goodfirmsimg">
              <Link
                to="https://www.goodfirms.co/company/sharptip-technologies-private-limited"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/goodfirms.svg" alt="good firms" loading="lazy" ></img>
              </Link>
            </div>
            <div className="clutchimg">
              <Link
                to="https://clutch.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/clutch.png" alt="good firms" loading="lazy" ></img>
              </Link>
            </div>
        </div>
      </div>

      {/* <div
        className="image"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1800"
      >
        <img
          src="images/blue.png"
          alt="sharptiptech"
        />
      </div>  */}
    </section>

        {/* <div
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1400"
            className="wearein"
          >
            <div className="inner">
              <div>
                <p>We are in</p>
              </div>
              <div className="line">
                <div></div>
              </div>
            </div>

            
        </div> */}
    </div>

    </>

    
  );
}
