import React, { useEffect } from "react";
import './blog.css';
import { datas } from "../../blogdata";
import { Link } from "react-router-dom";
import { scrollUp } from "../../Layouts/backtotop/Backtotop";



export default function Bloglist(){

    useEffect(()=>{
        scrollUp()
    },[])
    
    return(
        <>
        <div className="blog-container">

        {
            datas.map((data)=>{
                
            return(
        
          
            <div className="blog-box" key={data.id} >
            <div className="blog-img"  >
                <img src={data.image} alt={data.alt} loading="lazy" ></img>
            </div>
            <div className="blog-text">
                <span>{data.date} / {data.tag}</span>
                
                <p><Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}`} className="blog-title">{data.title}</Link></p>

                <p>{data.intro}</p>
                {/* {console.log("title", data.title.toString())} */}
                <Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}` }>Read More...</Link>
            </div>
            </div>  
            
        )
            
        })
    }
    </div>
        </>
    )
}