import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Getintouch.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';

export default function Getintouch(){

    const form = useRef();

    const sendEmail = (e) => {
      setValue('IN')
      setSelects(' ')
      setClick(true)
      e.preventDefault();
  
      emailjs.sendForm('service_ta94kka', 'template_6g33xfe', form.current, 'KxeizGNychEw6XxNt')
        .then((result) => {
            console.log(result.text);
            setdialog(true)
            setClick(false)
            setTimeout(() => {
                setdialog(false)
            },2000)
            
            
        }, (error) => {
            console.log(error.text);
            setError(true)
            setTimeout(() => {
                setError(false)
            },2000)
        });
    e.target.reset()
    };

    setTimeout(() => {
        setClick(false)
    },3000)

    const [value, setValue] = useState()
    const [selects, setSelects] = useState()
    const [dialog, setdialog] = useState(false)
    const [click, setClick] = useState(false)
    const [error, setError] = useState(false)

    const displayMessage = () => {
        if(dialog === true ){
            return(
                <p className='toast'  data-aos='fade-left' data-aos-duration='1800'>Thanks for contacting us! We will get back to you soon</p>  
            ) 
            
        }else if(click === true){
            return(
                <p className='toast'  data-aos='fade-left' data-aos-duration='1800'>submitting your request . . .</p> 
            )
        }else if(error === true){
            return(
                <p className='toast'  data-aos='fade-left' data-aos-duration='1800'>Something went wrong!Try Again</p> 
            )
        }
    }

    return(
        <>
            
            <div >
                {displayMessage()}
            </div>
            
            <h2 id="contact" className="heading" data-aos='fade-up' data-aos-duration='1800'>Contact</h2>
            <div data-aos='fade-up' data-aos-duration='1850' >
                <p className='contactp' >If you have any inquiries related to the project,please do not hesitate<br/>  to reach out to us via email at <span><Link to="mailto:support@sharptiptech.com">support@sharptiptech.com</Link></span> or by using the form below.</p>
            </div>
            <div className="contact" >

                <div className="container" data-aos='fade-up' data-aos-delay='300' data-aos-duration='1900'>
                    <iframe width="500" title="myFrame" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d972.1906191565556!2d80.158757!3d12.922982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525ee9429e77a1%3A0xd4e72a2f4a6098df!2sVKN%20Dream%20Homes%20Sembakkam!5e0!3m2!1sen!2sin!4v1675403645916!5m2!1sen!2sin"></iframe>
                </div>

                <div  className="contact-form" data-aos='fade-up' data-aos-delay='100' data-aos-duration='1900'>
                <h2  className='getintouch'>Get in touch with us</h2>
                <div className='form'>
                    <form  ref={form} method="post" onSubmit={sendEmail} >
                        <div className="name">
                            <input className="fname" type="text" name="name" placeholder="First Name" required />
                            <div>
                            <PhoneInput name='phone'
                              placeholder="Enter phone number" 
                              international
                              defaultCountry='IN'
                              maxLength="18"
                              required
                              value={value}
                              className="phone"
                              onChange={setValue}
                              
                            />
                            </div>
                        </div>
                        <input type="email" name="email" placeholder="Your Email" required /><br/>
                        <select name='subject' id="options" value={selects} onChange={e=>setSelects(e.target.value)} required  >
                            <option value="none" hidden defaultValue> Looking For</option>
                            <option value="Product consulting">QA Service</option>
                            <option value="Hybrid Mobile App">Hybrid Mobile App</option>
                            <option value="Full Stack Developement">Full Stack Developement</option>
                            <option value="Application Migration Service">Application Migration Service</option>
                            <option value="Testing Services">Application Maintainance Service</option>
                            <option value="Product consulting">Application Modernization Service</option>
                            <option value="Other">Others</option>
                        </select>
                        <textarea type="text" name="message" placeholder="Message" required /><br/>
                        <div className='submitbtn'>
                            <input type="submit" htmltype='reset' value="submit"  ></input>   
                        </div>

                    </form>
                    </div>
                </div>  
                
           
            </div>
        </>
    )
}