import React, { useEffect } from "react";
import './ApplicationModernization.css';
import Getintouch from "../getintouch/Getintouch";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { scrollUp } from "../../Layouts/backtotop/Backtotop";


export default function ApplicationModernization(){

    useEffect(()=>{
        scrollUp()
    },[])
    return(
        <>
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Application Modernization</title>
                <link rel="canonical" href="/application_modernization" />
                <meta name="robots" content="all, index, follow"  />
                <meta property="og-locale" content="en_US"  />
                <meta property="og-type" content="website"  />
                <meta property="og-title" content="Application Modernization" />
                <meta property="og-description" content="Application modernization refers to the process of enhancing and upgrading your existing software applications to adapt to the evolving environment. This involves improving functionality enhancing user experience and aligning your applications, with the technologies and industry norms. Our team of experts will assist you in navigating this journey helping you unlock the potential of your software and propel your business ahead. It's a decision aimed at maintaining competitiveness and agility, in todays paced market."/>
                
                <meta property="og-url" content="/solution" />
                <meta property="og-site_name" content="Sharptip Technologies" />
                <meta property="og-image" content="/images/logo1.png" />
                <meta property="og:image:secure_url" content="/images/logo1.png" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="Application modernization refers to the process of enhancing and upgrading your existing software applications to adapt to the evolving environment. This involves improving functionality enhancing user experience and aligning your applications, with the technologies and industry norms. Our team of experts will assist you in navigating this journey helping you unlock the potential of your software and propel your business ahead. It's a decision aimed at maintaining competitiveness and agility, in todays paced market." />
                <meta name="twitter:title" content="Application Modernization" />
                <meta name="twitter:image" content="/images/logo1.png" />

            </Helmet>
            <div className="application_modernization_container" >
                <div className="application_modernization_head" >
                <h2>Application Modernization Services</h2>
                <p>Application modernization is a critical process for businesses looking to stay competitive and efficient in today's rapidly evolving technological landscape. This concept involves updating and enhancing existing software applications to meet current business needs, take advantage of new technologies, and address issues related to performance, security, and scalability.
               </p>
            </div>  


                <section className="services">
                    
                    <div className="s-heading">
                        <h1  >Our Services</h1>
                        <p>We offer a range of services to assist your business in achieving successful application modernization.</p>
                    </div>
                   
                    <div className="s-box-container">
                    
                    <div className="s-box">
                    
                    <div className="bar"></div>
                    
                    <img alt="application modernization services" src="./images/png/innovation.png" loading="lazy" />
                    
                    <h1>Legacy Application Assessment</h1>
                    
                        <p>Our experts will thoroughly assess your legacy applications to identify areas that require modernization.</p>
                   
                    </div>
                        
                    <div className="s-box">
                   
                    <div className="bar"></div>
                   
                    <img alt="application modernization" src="./images/png/digital-transformation.png" loading="lazy" />

                    
                    <h1>Strategic Modernization Planning</h1>
                    
                        <p>We'll work with you to create a modernization roadmap tailored to your business objectives and budget.</p>
                    
                    
                    </div>
                       	
                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application migration strategy" src="./images/png/transition.png" loading="lazy" />
                        <h1>Application Refactoring</h1>
                        <p>We specialize in restructuring legacy code to improve performance, scalability, and maintainability.</p>
                    </div>
                
                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application migration in cloud" src="./images/png/normalization.png" loading="lazy" />
                        <h1>Cloud Migration and Integration</h1>
                        <p>Move your applications to the cloud and seamlessly integrate them with your existing systems.</p>
                    </div>

                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application modernization services" src="./images/png/responsive-design.png" loading="lazy" />
                        <h1>Microservices Architecture</h1>
                        <p>Transform your monolithic systems into microservices for enhanced flexibility and efficiency.</p>
                    </div>

                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application rebuilding" src="./images/png/seo.png" loading="lazy" />
                        <h1>Full Application Rebuilding</h1>
                        <p>If your applications need a complete overhaul, we can build modern solutions from the ground up.</p>
                    </div>

                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application modernization strategy" src="./images/png/digital.png" loading="lazy" />
                        <h1>Testing and Quality Assurance</h1>
                        <p>We ensure that modernized applications meet performance, security, and functionality requirements.</p>
                    </div>

                    <div className="s-box">
                        <div className="bar"></div>
                        <img alt="application service" src="./images/png/future.png" loading="lazy" />
                        <h1>Deployment and Support</h1>
                        <p>We'll deploy the updated applications with minimal disruption and provide ongoing maintenance and support.</p>
                    </div>


                </div>


                </section>
            </div>
            <div style={{padding:' 1rem 8%', textAlign: 'center'}} >
                <h2 className="services_heading" >Ready to Modernize Your Application?</h2>
                <p className="para">Is your software application starting to show its age? It might be time to consider modernization. We're here to help you transform your legacy application into a cutting-edge, efficient, and competitive asset.
                </p>
            </div>
            <Getintouch/>
        </HelmetProvider>
        </>
    )
}