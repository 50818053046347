import React from "react";
import './Solutions.css';
import { Link } from "react-router-dom";

export default function Solutions(){

    return(
        <>
        
        <section  id="services" className="solutions" >
            <div className="row">
                <h2  className="section-heading heading">Our Services</h2>
            </div>
            <div className="row" >
                <div className="column" data-aos='fade-up' data-aos-duration='1300' >
                    <Link to="/application_maintainance"  target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-hammer"></i>
                            </div>
                            <h3>Application Maintainance</h3>
                            <p>
                            Our Application Maintenance & Support Services are designed to ensure the smooth, secure, and efficient operation of your software applications, helping you extract maximum value from your technology investments.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="column" data-aos='fade-up' data-aos-duration='1300' data-aos-delay='500' >
                    <Link to="/application_migrations"  target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-brush"></i>
                            </div>
                            <h3>Application Migrations</h3>
                            <p>
                            Application migration is the process of seamlessly transferring your software applications from one environment to another, ensuring improved performance, enhanced security, and scalability.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="column" data-aos='fade-up' data-aos-duration='1300' data-aos-delay='600' >
                    <Link to="/application_modernization"  target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-wrench"></i>
                            </div>
                            <h3>Application Modernization</h3>
                            <p>
                            Application modernization is a critical process for businesses looking to stay competitive and efficient in today's rapidly evolving technological landscape.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="column" data-aos='fade-up' data-aos-duration='1300' data-aos-delay='700' >
                    <Link to="/qa-services"  target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-truck-pickup"></i>
                            </div>
                            <h3>QA Services</h3>
                            <p>
                            In today's fast-paced and highly competitive business landscape, ensuring the quality of your products and services is paramount.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="column" data-aos='fade-up' data-aos-duration='1300' data-aos-delay='800' >
                    <Link to="/custom_software_solution"  target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-broom"></i>
                            </div>
                            <h3>Custom Software Solutions</h3>
                            <p>
                            Custom software solutions are becoming increasingly essential for businesses looking to streamline operations, enhance productivity, and gain a competitive edge.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="column" data-aos='fade-up' data-aos-duration='1300' data-aos-delay='900' >
                    <Link to="/hybrid_mobile_app_services" target="_blank" rel="noopener noreferrer" >
                        <div className="card">
                            <div className="icon-wrapper">
                            <i className="fas fa-broom"></i>
                            </div>
                            <h3>Hybrid Mobile App Services</h3>
                            <p>
                            Our Hybrid Mobile App Services combine the strengths of both native and web applications, delivering a versatile, cross-platform solution that addresses the diverse needs of modern businesses and individuals.
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
            </section>
        </>
    )
}