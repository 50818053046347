import React, { useEffect } from "react";
import './ApplicationMigrations.css';
import Getintouch from "../getintouch/Getintouch";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { scrollUp } from "../../Layouts/backtotop/Backtotop";


export default function ApplicationMigrations(){

    useEffect(()=>{
        scrollUp()
    },[])

    return(

        <>
        <HelmetProvider>
        
            <Helmet>
                <meta charSet="utf-8" />
                <title>Application Migrations</title>
                <link rel="canonical" href="/application_migrations" />
                <meta name="robots" content="all, index, follow"  />
                <meta property="og-locale" content="en_US"  />
                <meta property="og-type" content="website"  />
                <meta property="og-title" content="Full stack Software Development Company" />
                <meta property="og-description" content="Migrating your software applications to an secure environment is known as application migration. This process can bring about benefits such, as performance, increased scalability, cost savings and improved security."/>
                <meta
                name="keywords" 
                content="application migration services,app migration services,application migration company,custom application migration services,legacy application migration services"
                />
                <meta property="og-url" content="/solution" />
                <meta property="og-site_name" content="Sharptip Technologies" />
                <meta property="og-image" content="/images/logo1.png" />
                <meta property="og:image:secure_url" content="/images/logo1.png" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="Migrating your software applications to an secure environment is known as application migration. This process can bring about benefits such, as performance, increased scalability, cost savings and improved security." />
                <meta name="twitter:title" content="Sharptip Technologies - Application Migrations" />
                <meta name="twitter:image" content="/images/logo1.png" />

            </Helmet>
            <div className="application_migration_container" >

                <div className="application_migration_head" >
                   <h2>Application Migration Services</h2>
                   <p>Application migration is the process of seamlessly transferring your software applications from one environment to another, ensuring improved performance, enhanced security, and scalability. Our expert team at sharptip technologies, specializes in guiding your applications through this transition with precision and efficiency. 
                    <br />
                    <br />
                    Whether you need to upgrade to the latest version, shift to the cloud, or migrate to a new platform, we have the expertise to make it happen. Trust us for a smooth and reliable migration experience, keeping your business at the forefront of technology.</p>
                </div>  


                <h2 className="services_heading" >Our services</h2>
                <div className="our_services" >
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (2).png" alt="Application Migration" loading="lazy"  />

                        <div className="term_content" >
                            <h3>Application Platform Migration</h3>
                            <p>Is your current platform outdated or no longer serving your business's needs? We can migrate your application to a modern platform that enhances performance and security.</p>
                        </div>
                    </div>
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (4).png" alt="application migration service provided company" loading="lazy"  />

                        <div className="term_content" >
                            
                            <h3>Version Upgrades</h3>
                            <p>Is your current platform outdated or no longer serving your business's needs? We can migrate your application to a modern platform that enhances performance and security.</p>
                        </div>

                    </div>
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (4).png" alt="application services" loading="lazy"  />
                        <div className="term_content" >

                            <h3>Cloud Migration</h3>
                            <p>Is your current platform outdated or no longer serving your business's needs? We can migrate your application to a modern platform that enhances performance and security.</p>
                        </div>

                    </div>
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (5).png" alt="software develoment" loading="lazy"  />

                        <div className="term_content" >
                            <h3>Data Migration</h3>
                            <p>We ensure a secure and efficient transfer of your data from one database to another, maintaining data integrity throughout the process.</p>
                        </div>

                    </div>
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (6).png" alt="Application migration steps" loading="lazy" />
                        <div className="term_content" >

                            <h3>Testing and Validation</h3>
                            <p>We conduct rigorous testing to ensure your application functions flawlessly in its new environment. User acceptance testing is also included to validate that your software meets your users' expectations.</p>
                        </div>

                    </div>
                    <div className="term" >
                        <img src="/images/Evolution of Mobile App Development (8).png" alt="application development services" loading="lazy"  />

                        <div className="term_content" >
                            <h3>Documentation and Training</h3>
                            <p>We provide comprehensive documentation and training materials to help your team adapt to the changes and make the most of your newly migrated application.</p>
                        </div>

                    </div>
                </div>

                <div>
                <h2 className="services_heading" >Ready to Migrate?</h2>
                <p className="para">If you're ready to take your software application to the next level, we're here to help. Contact us today to discuss your application migration needs and take the first step toward a smoother, more efficient, and more powerful software environment.
                </p>
                </div>
            <Getintouch />
            </div>
        </HelmetProvider>
        </>
    )
}