import React, { useEffect } from "react";
import './ApplicationMaintance.css';
import Getintouch from "../getintouch/Getintouch";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { scrollUp } from "../../Layouts/backtotop/Backtotop";



export default function ApplicationMaintance(){

    useEffect(()=>{
        scrollUp()
    },[])

    return(
        <>
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Application Maintainance | Application Support and Maintenance Services</title>
                <link rel="canonical" href="/application_maintainance" />
                <meta name="robots" content="all, index, follow"  />
                <meta property="og-locale" content="en_US"  />
                <meta property="og-type" content="website"  />
                <meta property="og-title" content="Full stack Software Development Company" />
                <meta property="og-description" content="
                    Our services, for application support and maintenance are designed to address issues such as updating applications fixing bugs and customizing systems. If you're looking for software maintenance and support solutions sharptip technologies is the choice. We have experience, in handling these types of challenges."/>
                <meta property="og-url" content="/solution" />
                <meta property="og-site_name" content="Sharptip Technologies" />
                <meta property="og-image" content="/images/logo1.png" />
                <meta property="og:image:secure_url" content="/images/logo1.png" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="
                Our services, for application support and maintenance are designed to address issues such as updating applications fixing bugs and customizing systems. If you're looking for software maintenance and support solutions sharptip technologies is the choice. We have experience, in handling these types of challenges." />
                <meta name="twitter:title" content="Sharptip Technologies" />
                <meta name="twitter:image" content="/images/logo1.png" />

            </Helmet>
        
        <div className="application_maintainance_container" >

            <div className="application_maintaince" >
                <h1>Application Maintenance & Support Services</h1>
                <b><p>Get application maintenance and support services that drive your business forward.</p></b>
                <p>Our Application Maintenance & Support Services are designed to ensure the smooth, secure, and efficient operation of your software applications, helping you extract maximum value from your technology investments.</p>
                <center><button >See more<i style={{marginLeft: '1rem', fontSize: '16px'}} className="fa-solid fa-arrow-down"></i></button></center>
            </div>
            
            {/* <h2 className="heading" >What we offer?</h2>
            <div className="timeline">
                <div className="outer">
                    <div className="card"   >
                        <div className="card_info" data-aos="flip-right"  data-aos-duration='400' >
                            <h3 className="title">Bug Fixes</h3>
                            <p>Addressing and resolving any existing issues or bugs within the application promptly. </p>
                        </div>
                    </div>
                    <div className="card"  >
                        <div className="card_info" data-aos="flip-left"  data-aos-duration='500'  >
                            <h3 className="title">Security Updates</h3>
                            <p> Implementing security patches and updates to protect our application from potential threats and vulnerabilities. </p>
                        </div>
                    </div>
                    <div className="card"   >
                        <div className="card_info" data-aos="flip-right"  data-aos-duration='600' >
                            <h3 className="title">Performance Optimization</h3>
                            <p>Identifying and addressing performance bottlenecks to ensure the application runs smoothly.</p>
                        </div>
                    </div>
                    <div className="card"   >
                        <div className="card_info" data-aos="flip-left"  data-aos-duration='700' >
                            <h3 className="title">Regular Backups</h3>
                            <p>Implementing a robust backup and recovery strategy to prevent data loss in case of unexpected incidents. </p>
                        </div>
                    </div>
                    <div className="card"   >
                        <div className="card_info" data-aos="flip-right"  data-aos-duration='800' >
                            <h3 className="title">Compatibility Updates</h3>
                            <p>Ensuring that the application remains compatible with the latest operating systems, web browsers, and hardware. </p>
                        </div>
                    </div>
                    <div className="card"  >
                        <div className="card_info" data-aos="flip-left"  data-aos-duration='900'  >
                            <h3 className="title">Feature Enhancements</h3>
                            <p>Implementing new features or improvements as needed to meet changing business requirements. </p>
                        </div>
                    </div>
                    <div className="card" >
                        <div className="card_info" data-aos="flip-right"  data-aos-duration='1000'  >
                            <h3 className="title">Technical Support</h3>
                            <p>Providing responsive technical support to address any issues or concerns that may arise during the usage of the application. </p>
                        </div>
                    </div>
                </div>
            </div>  */}


            <div className="chooseus-section" >

                <h4><span>What we</span> offer?</h4>

                <p>Our Application Maintenance & Support Services are designed to ensure the smooth, secure, and efficient operation of your software applications, helping you extract maximum value from your technology investments.</p>
                <div className="chooseusinner" >
                    <div >
                        <div className="item"> <span className="icon feature_box_col_one"><i className="fa-solid fa-bug"></i></span>
                        
                            <h6>Bug Fixes</h6>
                            <p>Addressing and resolving any existing issues or bugs within the application promptly.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_two"><i className="fa fa-anchor"></i></span>
                            <h6>Security Updates</h6>
                            <p>Implementing security patches and updates to protect our application from potential threats and vulnerabilities.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_three"><i className="fa fa-hourglass-half"></i></span>
                            <h6>Performance Optimization</h6>
                            <p>Identifying and addressing performance bottlenecks to ensure the application runs smoothly.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_four"><i className="fa fa-database"></i></span>
                            <h6>Regular Backups</h6>
                            <p>Implementing a robust backup and recovery strategy to prevent data loss in case of unexpected incidents.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_five"><i className="fa fa-upload"></i></span>
                            <h6>Compatibility Updates</h6>
                            <p>Ensuring that the application remains compatible with the latest operating systems, web browsers, and hardware.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_six"><i className="fa-solid fa-wand-magic-sparkles"></i></span>
                            <h6>Feature Enhancements</h6>
                            <p>Implementing new features or improvements as needed to meet changing business requirements.</p>
                        </div>
                    </div>
                    <div >
                        <div className="item"> <span className="icon feature_box_col_six"><i className="fa-solid fa-headset"></i></span>
                            <h6>24/7 Technical Support</h6>
                            <p>Providing responsive technical support to address any issues or concerns that may arise during the usage of the application.</p>
                        </div>
                    </div>
                </div>
            </div>
      
            <div className="technologies-section"  >
                <div className="patterns" >

                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#FF0066" d="M32.6,-56.9C42.6,-50.8,51,-42.6,58.7,-32.7C66.3,-22.9,73.1,-11.4,77.1,2.3C81,16,82.2,32.1,73.6,40.3C65,48.5,46.7,48.8,32.8,49.7C18.9,50.5,9.5,51.8,-1.2,53.8C-11.8,55.8,-23.5,58.5,-34.9,56.2C-46.3,53.9,-57.4,46.7,-58.3,36.5C-59.2,26.3,-50.1,13.2,-43.1,4C-36.2,-5.1,-31.4,-10.3,-30,-19.5C-28.5,-28.8,-30.3,-42.1,-25.8,-51.3C-21.4,-60.5,-10.7,-65.5,0.3,-66.1C11.4,-66.7,22.7,-62.9,32.6,-56.9Z" transform="translate(100 100)" />
                </svg>
                </div>
                <h4>Technologies we worked on</h4>
                <div className="box-container" >
                    <div className="box-inner" >
                        <div className="box" >
                            <i className="fa-solid fa-globe"></i>
                        </div>
                        <p>Web apps</p>
                    </div>
                    <div className="box-inner" >
                        <div className="box" >
                        <i className="fa-solid fa-mobile-screen-button"></i>
                        </div>
                        <p>Mobile apps</p>
                    </div>
                    <div className="box-inner" >
                        <div className="box" >
                        <i className="fa-solid fa-cloud-arrow-up"></i>
                        </div>
                        <p>Cloud apps & SaaS</p>
                    </div>
                    <div className="box-inner" >
                        <div className="box" >
                            <i className="fa-solid fa-server"></i>
                        </div>
                        <p>Database apps</p>
                    </div>
                    <div className="box-inner" >
                        <div className="box" >
                            <i className="fa-solid fa-computer"></i>
                        </div>
                        <p>Desktop apps</p>
                    </div>
                    <div className="box-inner" >
                        <div className="box" >
                            <i className="fa-brands fa-react"></i>
                        </div>
                        <p>React</p>
                    </div>

                </div>
            </div>
            <div style={{padding:' 1rem 8%', textAlign: 'center'}} >
                <h2 className="services_heading" >Ready to Enhance and Maintain Your Application?</h2>
                <p className="para">If you're looking to keep your software application running smoothly and up-to-date, we're here to support you. Our team of experts is ready to provide top-notch maintenance services to ensure your application remains reliable, secure, and up-to-date.
                </p>
            </div>
            <Getintouch/>
        </div>
        </HelmetProvider>
        </>
    )
}