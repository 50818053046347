import React from "react";
import './blogviewpage.css'
import { Link, useParams } from "react-router-dom";
import { datas } from "../../blogdata";
import { useEffect } from "react";
import { useState } from "react";
import { scrollUp } from "../../Layouts/backtotop/Backtotop";


export default function Blogviewpage(){


    const {id}= useParams()

    const [blog, setblogdata] = useState('')

    useEffect(()=>{
        let blog = datas.find(blogs=>blogs.title.toString().replace(/[^a-zA-Z0-9 ]/,  '') === id)

        if(blog){
            setblogdata(blog)
        }

    },[id])

    useEffect(()=>{
        scrollUp()
    },[])
    

    return(
        <>
      
        <div className="blogview-container" >
            <div className="blogview-header">
                
                <Link to="/blog"><i className="fa-solid fa-arrow-left"></i> Back to </Link>
            </div>  

            <div className="blogview-content">
                
                <div className="blogview-title">
                    <h1>{blog.title}</h1>
                    <p>{blog.date} / {blog.tag}</p>
                </div>    

                <div className="blogview-img">
                    <img src={blog.image} alt={blog.alt} loading="lazy" ></img>
                </div>
                
                <div className="description" >
                    {blog.description}
                </div>
            </div>
        </div>
        
        <div className="related-products">
            <h3>Related Articles</h3>
           
            <div className="blogviewcontainer">
            {
            datas.map((data)=>{
                if(data.id >= 6 && data.id <9){
                    return(
        
                      
                        <div className="blog-box" key={data.id} >
                            <div className="blog-img"  >
                                <img src={data.image} alt={data.alt} loading="lazy" ></img>
                            </div>
                            <div className="blog-text">
                                <span>{data.date} / {data.tag}</span>
                                
                                <p><Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}`} className="blog-title">{data.title}</Link></p>
        
                                <p>{data.intro}</p>
                                
                                <Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}` }>Read More...</Link>
                            </div>
                            </div>  
                            
                        )
                } else {
                    return null;
                  }
              
                })
            }
                    </div>
                </div>
                
        </>
    )
}