import React, { useState } from "react";
import './Navbar.css';
import { Link, useLocation } from "react-router-dom";


export default function Navbar(){
    const [fix, setfix] = useState(false)

    const setFixed = ()=>{
        if(window.scrollY >= 2){
            setfix(true)
        }else{
            setfix(false)
        }
    }

    window.addEventListener("scroll", setFixed)

    const path = useLocation()

    return(
        
         <div>
        
        <header  className={fix ? "headerfix" : "header"}  > 
            
                <a href="https://sharptiptech.com/"><img className="logo" src="/images/logo1.png" alt="sharptiptech logo" loading="lazy" ></img></a>

                <input type="checkbox" id="menu-bar" />
                {/* <label for="menu-bar" className="fas fa-bars"></label> */}

                <label htmlFor="menu-bar"><i className="fa-solid fa-bars"></i></label>
                

                <nav className="navbar"  >
                    <Link style={{ color: !fix && path.pathname === "/" ? '#fff' : '#118AB2' }} to="/">Home</Link>
                    <a style={{ color: !fix && path.pathname === "/" ? '#fff' : '#118AB2' }} href= {path.pathname === "/" ? "#services" : "/" } >Services</a>
                    {/* <span >
                        <a  href="https://pointsly.in/" target="_blank" rel="noreferrer">
                            Products
                        </a>

                    </span>  */}

                    {/* <a href="#about">About</a> */}
                    <a style={{ color: !fix && path.pathname === "/" ? '#fff' : '#118AB2' }} href={path.pathname === "/" ? "#contact" : "/" }>Contact</a>
                    <Link style={{ color: !fix && path.pathname === "/" ? '#fff' : '#118AB2' }} to= "/blog" >Blog</Link>
                </nav>
            
        </header>
         </div>
       
    )
}