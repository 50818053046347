import React from "react";
import Headersection from '../../components/headersection/Headersection';
import TechStack from '../../components/teckstack/Techstack';
// import Services from '../../components/services/Services';
import Getintouch from '../../components/getintouch/Getintouch';
import Infoblock from '../../components/infoblock/Infoblock';
import Product from '../../components/clients&projects/Products';
import { datas } from "../../blogdata";
import { Link } from "react-router-dom";
import "./Homepage.css";
import Solutions from "../../components/solutions/Solutions";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function HomePage(){

    
    return(
        <>
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://sharptiptech.com" />
                <meta name="robots" content="all, index, follow"  />
                <meta property="og-locale" content="en_US"  />
                <meta property="og-type" content="website"  />
                <meta property="og-title" content="Full stack Software Development Company" />
                <meta property="og-description" content="Full stack Software Development Company"/>
                <meta
                name="keywords" 
                content="website development company chennai,best website development company in chennai,best mobile app development company in tamilnadu,mobile app development company in chennai,mobile app development,application development,mobile app development,best mobile app development company in tamilnadu,mobile app development company in chennai,website app and mobile app development in salem,website and mobile app development company,website and mobile app development,website and android app development,reactjs development company in chennai,reactjs development company in salem,reactjs development company in india,react development companies in salem,react development companies in chennai,react website development in salem,flutter app development company in india,flutter app development services,flutter app development company in chennai,flutter app development company in salem,web development in salem,web development in chennai,app development in chennai,app development in salem,low cost website development in chennai,cheapest place to create a website,best website development company in chennai,best Mobile app development company in chennai,chennai web developement company,salem web development company,chennai app development company,salem app development company,website development services,web design company in chennai,best mobile app development company in chennai,best app development company in chennai,top mobile app development companies in chennai,best app development chennai,leading mobile application developers in chennai,web designing company in chennai,best website designing company in chennai,top web development companies in chennai,best web design and development company in chennai,ecommerce website designing company in chennai,best mobile app development company in chennai,best android app development company in chennai,web development company in salem"
                />
                <meta property="og-url" content="https://sharptiptech.com" />
                <meta property="og-site_name" content="Sharptip Technologies" />
                <meta property="og-image" content="/images/logo1.png" />
                <meta property="og:image:secure_url" content="/images/logo1.png" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="summary_large_image" />
                <meta name="twitter:title" content="Sharptip Technologies" />
                <meta name="twitter:image" content="/images/logo1.png" />

            </Helmet>
    
            <Headersection />
            {/* <Services /> */}
            <Solutions/>
            <Infoblock />
            <TechStack />
            <Product />

            <div className="homeblog" >
            <h3 className="heading"> Blogs</h3>
            <Link to="/blog"><p>View all <i className="fa-solid fa-arrow-right"></i></p></Link>
            <div className="blogviewcontainer">
            {
            datas.map((data)=>{
                return(
                    data.id >= 6 && data.id <9 && 
        
                        <div className="blog-box" key={data.id}  >
                            <div className="blog-img"  >
                                <img src={data.image} alt={data.alt} loading="lazy" ></img>
                            </div>
                            <div className="blog-text">
                                <span>{data.date} / {data.tag}</span>
                                
                                <p><Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}`} className="blog-title">{data.title}</Link></p>
        
                                <p>{data.intro}</p>
                                
                                <Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}` }>Read More...</Link>
                            </div>
                        </div>  
                
                    )

                })
            }
                </div>
            </div>
                
            <Getintouch />
        </HelmetProvider>
        </>
    )
}