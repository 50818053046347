import React from "react";
import './Footer.css';

export default function Footer(){
   
    return(
        // style={{background:`url(/images/footer-bg.png)`, backgroundRepeat  : 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} 
        <footer data-aos='fade-up'  data-aos-duration='1500' className="footer">

        <div className="box-container" >

            <div className="box" id="about">
                {/* <h3>About us</h3>
                <p>We support startups and enterprise in the digital transformation journey by creating custom software solutions for their business. Our journey has started with the mission of digital transformation made easy. We have a team of cool developers who strive to take up challenges.</p> */}
                <img src="/images/logo1.png" alt="logo" loading="lazy" ></img>
                <div className="flex" >
                    
                <p>See our reviews in </p>
                {/* className="trustpilot-widget" */}
                <div  data-locale="en-US" style={{color: '#fff', marginTop: '9px'}} data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="64a2867928632f8baaaa6436" data-style-height="50px" data-style-width="50%" data-theme="light" data-min-review-count="10" data-without-reviews-preferred-string-id="1" data-style-alignment="center" >
                    
                    <a href="https://www.trustpilot.com/review/sharptiptech.com" target="_blank" rel= "noreferrer noopener"> Trustpilot</a>
                    </div>

                </div>
            </div>

            <div className="box">
                <h3>Quick Links</h3>
                <a href="https://sharptiptech.com/">Home</a>
                <a href="https://www.pointsly.in/" target="_blank" rel="noreferrer">Products</a>
                <a href="#services">Services</a>
                <a href="#contact">Contact</a>
            </div>

            <div className="box">
                <h3>Follow us</h3>
                <a href="https://www.facebook.com/profile.php?id=100092262129025&mibextid=ZbWKwL" target="_blank" rel="noreferrer">Facebook</a>
                <a href="https://www.linkedin.com/company/sharptip-technologies-private-limited/" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>

            <div className="box">
                <h3>Contact info</h3>
                
                {/* <div className="info">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>
                    <p><b>Registered Office Address:</b> <br/>Sembakkam, Chennai, Tamil Nadu - 600073.
                     
                     </p>
                    
                </div> */}
                <div className="info">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>
                   
                    <p><b>Development Center Address:</b> <br/>Pallapatti, New busstand, Salem,<br/> Tamil Nadu - 636009. 
                     </p>
                    
                </div>

                <div className="info">
                <i className="fa-brands fa-whatsapp"></i>
                    <p> +91 73058 43938</p>
                </div>
                <div className="info">
                    <i className="fa-sharp fa-solid fa-envelope"></i>
                    <p> support@sharptiptech.com </p>
                </div>
            </div>

        </div>
                
        <h1 className="credit"> &copy; copyright @ 2023 | Sharptip Technologies </h1>
        
        </footer>
        
    )
}