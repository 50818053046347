import React from "react";
import './Techstack.css';

export default function TechStack(){

    return(
        <>
        <section className="techstacksection" >
            <h2 className="heading" data-aos='fade-up' data-aos-duration='1500'>Technology Stack</h2>
            <div className="slider"  >
                
                <div className="slidetrack">
                    <div className="slide" data-aos='fade-up' data-aos-duration='1500'>
                        <img src="images/logos/reactjs.png" alt="web app development company in chennai" loading="lazy" /> 
                        <p>React JS</p>
                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='400'  data-aos-duration='1400'>
                        <img src="images/logos/angular.png" alt="android app development company in chennai" loading="lazy" /> 
                        <p>Angular JS</p>

                    </div>
                    
                    
                    <div className="slide" data-aos='fade-up' data-aos-delay='200'  data-aos-duration='1500'>
                        <img src="images/logos/typescript.png" alt="web application development company chennai" loading="lazy" /> 
                        <p>Type Script</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='100' data-aos-duration='1500'>
                        <img src="images/logos/python.png" alt="e commerce app development company in chennai" loading="lazy"  /> 
                        <p>Python</p>
                    </div>
                   
                   
                    <div className="slide" data-aos='fade-up' data-aos-delay='500'  data-aos-duration='1300'>
                        <img src="images/logos/nodejs.png" alt="ios app development company in chennai" loading="lazy" /> 
                        <p>Node JS</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='600'  data-aos-duration='1200'>
                        <img src="images/logos/flutter.png" alt="mobile app development company in chennai" loading="lazy" /> 
                        <p>Flutter</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='600'  data-aos-duration='1200'>
                        <img src="images/logos/asp dotnet.png" alt="mobile app development company in chennai" loading="lazy" /> 
                        <p>ASP .Net</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='300'  data-aos-duration='1500'>
                        <img src="images/logos/aws.png" alt="mobile app development company in chennai" loading="lazy" /> 
                        <p>AWS</p>

                    </div>
                  
                    <div className="slide" data-aos='fade-up' data-aos-delay='700'  data-aos-duration='1100'>
                        <img src="images/logos/mangodb.png" alt="mobile app development companies chennai india" loading="lazy" /> 
                        <p>Mango DB</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='800'  data-aos-duration='1200'>
                        <img src="images/logos/mysql.png" alt="mysql" loading="lazy" /> 
                        <p>My SQL</p>

                    </div>
                    <div className="slide" data-aos='fade-up' data-aos-delay='900'  data-aos-duration='1250'>
                        <img src="images/logos/affinity.png" alt="iphone app development company in chennai" loading="lazy" /> 
                        <p>Affinity</p>

                    </div>
                    <div  className="slide" data-aos='fade-up' data-aos-delay='1000'  data-aos-duration='1300'>
                        <img src="images/logos/figma.png" alt="best web design company" loading="lazy" /> 
                        <p>Figma</p>

                    </div>
                  

                </div>
            </div>
        </section>
        
        </>
    )

}