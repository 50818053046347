import React from "react";
import './Products.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Autoplay  } from 'swiper/modules';


export default function Product(){
    
    return(
        <>
            <section className="clients_section" >
                <h2 className="heading" data-aos='fade-up' data-aos-duration='1500'>Our Clients Says</h2>
                {/* <div className="product-container"  >
                    
                    <div className="products">
                        <div  data-aos='fade-up' data-aos-duration='1500'>
                            <img className="clients-img" src="images/flagit logo.png" /> 
                        </div>

                        <div data-aos='fade-up' data-aos-duration='1500'>
                            <img  className="clients-img" src="images/maxpro logo.png"  /> 
                        </div>

                        <div data-aos='fade-up' data-aos-duration='1500'>
                            <img  className="clients-img" src="images/semmaisev.jpeg"  /> 
                        </div>

                    </div>
                </div> */}

                <div className="slidersection" >
                    <Swiper 
                        modules={[Navigation, Pagination, Autoplay ]}
                        // spaceBetween={10}
                        slidesPerView={'auto'}
                        loop={true}
                        autoplay={true}
                        pagination={{ clickable: true }}
                        // navigation
                        breakpoints={{
                            640: {
                              slidesPerView: 1,
                              
                            },
                            768: {
                              slidesPerView: 2,
                              
                            },
                            1024: {
                              slidesPerView: 2,
                             
                            },
                          }}
                    >
                        <SwiperSlide>
                        <div className="testimonials-section">

                            <div className="owl-carousel owl-theme testimonials-container">
                                
                                <div className="item testimonial-card">
                                    <main className="test-card-body">
                                        <div className="quote">
                                            <i className="fa fa-quote-left"></i>
                                            <h2>Vignesh</h2>
                                        </div>
                                        <p>I had the pleasure of working with SharpTipTech Technologies to create a website for my business, and I couldn't be more satisfied with the results. The entire process, from initial discussions to the final product, was a seamless and impressive experience.</p>
                                        <div className="ratings">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star uncheck"></i>
                                        </div>
                                    </main>
                                    <div className="profile">
                                        <div className="profile-image">
                                            <img alt="maxpro" src="/images/maxpro logo.png" loading="lazy" />
                                        </div>
                                        <div className="profile-desc">
                                            <span>Maxpro Infotech</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="testimonials-section">

                            <div className="owl-carousel owl-theme testimonials-container">
                                
                                <div className="item testimonial-card">
                                    <main className="test-card-body">
                                        <div className="quote">
                                            <i className="fa fa-quote-left"></i>
                                            <h2>Saravanan</h2>
                                        </div>
                                        <p>SharpTipTech demonstrated a high level of professionalism and expertise. They listened carefully to my requirements and offered valuable insights to enhance the project. Their team was incredibly responsive and communicative throughout, making sure I was kept in the loop at every step.</p>
                                        <div className="ratings">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                    </main>
                                    <div className="profile">
                                        <div className="profile-image">
                                            <img alt="semmai sevi" src="/images/semmaisev.jpeg" loading="lazy" />
                                        </div>
                                        <div className="profile-desc">
                                            <span>Semmai Sevi</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="testimonials-section">

                            <div className="owl-carousel owl-theme testimonials-container">
                                
                                <div className="item testimonial-card">
                                    <main className="test-card-body">
                                        <div className="quote">
                                            <i className="fa fa-quote-left"></i>
                                            <h2>Bhuvaneshwaran</h2>
                                        </div>
                                        <p>I would like to recommend SharpTipTech Technologies to anyone seeking web development services. Their commitment, expertise and customer focused approach make them an exceptional choice. I am grateful, for their assistance, in elevating my business to next level.</p>
                                        <div className="ratings">
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                            <i className="fa-solid fa-star"></i>
                                        </div>
                                    </main>
                                    <div className="profile">
                                        <div className="profile-image">
                                            <img alt="flagit" src="/images/flagit logo.png" loading="lazy" />
                                        </div>
                                        <div className="profile-desc">
                                            <span>Flagit</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </SwiperSlide>
                     
                        
                    </Swiper>

                </div>


            </section>
        </>
    )
}