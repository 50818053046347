import React from "react";
import { useEffect, useState } from "react";
import './Backtotop.css'

export default function Backtotop(){
    const [backtotop, setbacktotop] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY > 100){
                setbacktotop(true)
            }else{
                setbacktotop(false)
            }
        })
    },[])

    const scrollUp =() =>{
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
    }


    return(
        <>
        
            {backtotop && (
                <button  className="backtotopbtn"
                onClick={scrollUp}
                ><i style={{fontSize: '26px'}} className="fa-sharp fa-solid fa-caret-up"></i></button>
            )}

        </>
    )
}