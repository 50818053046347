import Aos from 'aos';
import { useEffect } from 'react';
import 'aos/dist/aos.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Termsandconditions from './components/termsandconditions/Termsandconditions';
import HomePage from './pages/HomePage/HomePage';
import Privacypolicy from './components/privacypolicy/Privacypolicy';
import Semmaiprivacypolicy from './components/semmai/privacypolicy';
import Blog from './components/blog/blog';
import Portfolio from './components/portfolio/Portfolio';
import Layout from './Layouts/Layout';
import Solutions from './components/solutions/Solutions';
import Blogviewpage from './pages/blogviewpage/blogviewpage';
import ApplicationMaintance from './components/application_maintainance/ApplicationMaintance';
import ApplicationMigrations from './components/application_migrations/ApplicationMigrations';
import ApplicationModernization from './components/application_modernization/ApplicationModernization';
import QAservices from './components/QAservices/QAservices';
import PageNotFound from './components/404/404page';
import Softwaresolution from './components/softwaresolution/Softwaresolution';
import HybridMobileAppServices from './pages/hybridmobileservices/Hybridmobileservices';
import Applicationmaintenanceservices from './pages/applicationmaintenanceservices/Applicationmaintenanceservices';
import Semmaisev from './pages/semmaisev/Semmaisev';


function App() {

  useEffect(()=>{
    Aos.init()
  },[])

  

  return (
    <>
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={ <HomePage/> } />
          <Route path='/blog' element={ <Blog/> } />
          <Route path='/blog/:id' element={ <Blogviewpage/> } />
          <Route path='/hungrybro_termsandconditions' element={ <Termsandconditions/> } />
          <Route path='/hungrybro_privacypolicy' element={ <Privacypolicy/> } />
          <Route path="/semmai_privacypolicy" element={ <Semmaiprivacypolicy/>  } />
          <Route path="/portfoliopage" element={ <Portfolio/>  } />
          <Route path="/solution" element={ <Solutions/>  } /> 
          <Route path="/application_maintainance" element={ <ApplicationMaintance/>  } />
          <Route path="/application_migrations" element={ <ApplicationMigrations/>  } />
          <Route path="/application_modernization" element={ <ApplicationModernization/>  } />
          <Route path="/qa-services" element={ <QAservices/>  } />
          <Route path="/custom_software_solution" element={ <Softwaresolution/>  } />
          <Route path="/hybrid_mobile_app_services" element={ <HybridMobileAppServices/>  } />
          <Route path="/application_maintenance_services" element={ <Applicationmaintenanceservices/>  } />
          <Route path="/semmaisevi" element={ <Semmaisev/>  } />
          <Route path="*" element={ <PageNotFound/>  } />
        </Routes>
      </Layout>
      </Router>
    </>
    
  );
}

export default App;
