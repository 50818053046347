import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Backtotop from "../components/backtotop/Backtotop";


const Layout = ({ children }) => {


  return (
    <>
      <Navbar />
        <main style={{marginTop: '10rem'}} >{children}</main>        
      <Footer />
      <Backtotop/>
    </>
  );
};

export default Layout;
