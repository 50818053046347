import React from "react";
import './blog.css'
import Bloglist from "./blogitem";


export default function Blog(){

    
    return(
        <>
        
        <Bloglist/>
       
        </>
    )
}