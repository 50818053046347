import React from "react";
import './Portfolio.css';


export default function() {
    return(
        <>
       
            <div className="portfolios">
                <div className="portfolio-container">
                    <div className="portfolio-content">
                        <h1>Semmai Sev</h1>
                        <p>Lorem ipsum dolar sit amet consectetur adipisicing elit. Nobis fugiat voluptates cumque minus consequatur sapiente provident incidunt repellat rem. Praesentium mollitia dolore vero amet pariatur maxim</p>
                        <div className="portfolio-services">
                            <p>Services</p>
                            <p>Flutter.Android</p>
                            <p>Mobile Development</p>
                            <p>services</p>
                        </div>
                    </div>
                    <div className="portfolio-image">
                        <img src="./images/python development.png" loading="lazy" ></img>
                    </div>
                </div>

                <div className="portfolio-container">
                    <div className="portfolio-content">
                        <h1>Semmai Sev</h1>
                        <p>Lorem ipsum dolar sit amet consectetur adipisicing elit. Nobis fugiat voluptates cumque minus consequatur sapiente provident incidunt repellat rem. Praesentium mollitia dolore vero amet pariatur maxim</p>
                        <div className="portfolio-services">
                            <p>Services</p>
                            <p>Flutter.Android</p>
                            <p>Mobile Development</p>
                            <p>services</p>
                        </div>
                    </div>
                    <div className="portfolio-image">
                        <img src="./images/python development.png" loading="lazy" ></img>
                    </div>
                </div>

                <div className="portfolio-container">
                    <div className="portfolio-content">
                        <h1>Semmai Sev</h1>
                        <p>Lorem ipsum dolar sit amet consectetur adipisicing elit. Nobis fugiat voluptates cumque minus consequatur sapiente provident incidunt repellat rem. Praesentium mollitia dolore vero amet pariatur maxim</p>
                        <div className="portfolio-services">
                            <p>Services</p>
                            <p>Flutter.Android</p>
                            <p>Mobile Development</p>
                            <p>services</p>
                        </div>
                    </div>
                    <div className="portfolio-image">
                        <img src="./images/python development.png" loading="lazy" ></img>
                    </div>
                </div>


                <div className="portfolio-container">
                    <div className="portfolio-content">
                        <h1>Semmai Sev</h1>
                        <p>Lorem ipsum dolar sit amet consectetur adipisicing elit. Nobis fugiat voluptates cumque minus consequatur sapiente provident incidunt repellat rem. Praesentium mollitia dolore vero amet pariatur maxim</p>
                        <div className="portfolio-services">
                            <p>Services</p>
                            <p>Flutter.Android</p>
                            <p>Mobile Development</p>
                            <p>services</p>
                        </div>
                    </div>
                    <div className="portfolio-image">
                        <img src="./images/python development.png"  loading="lazy" ></img>
                    </div>
                </div>
            </div>
              
           

        </>
    )
}