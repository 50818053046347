import React from "react";
import './Infoblock.css'


export default function Infoblock(){
    return(
        <div className="info-h" >
            <h2 data-aos='zoom-in' data-aos-duration='1000'>Why Choose Us</h2>
            <div className="info-bg"  >
                <div data-aos='zoom-in' data-aos-duration='1000' >
                    <i className="fa-sharp fa-solid fa-layer-group" ></i>
                    <h3 className="info-head" >Pricing</h3>
                    <p>Cost effective development solution.</p>
                </div>
                <div data-aos='zoom-in'  data-aos-duration='1500'>
                    <i className="fa-sharp fa-solid fa-code"></i>
                    <h3 className="info-head" >Design</h3>
                    <p>Best in className, crafted by industry experts </p>
                </div>
                <div data-aos='zoom-in' data-aos-duration='1800'>
                    <i className="fa-sharp fa-solid fa-paper-plane"></i>
                    <h3 className="info-head" >Delivery</h3>
                    <p>Faster and Timely delivery </p>
                </div>
                <div data-aos='zoom-in' data-aos-duration='1800'>
                <i className="fa-sharp fa-solid fa-credit-card"></i>
                    <h3 className="info-head" >Scalable<br/> Deployment </h3>
                    <p>Follow industry <br/>standards for secure & scalable deployment</p>
                </div>
                <div data-aos='zoom-in' data-aos-duration='1800'>
                <i className="fa-sharp fa-solid fa-headset"></i>
                    <h3 className="info-head" >Maintenance Support</h3>
                    <p>Low-cost post-delivery support</p>
                </div>
            </div>
        </div>
    )
}